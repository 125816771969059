export const phrases = [
  "Don't forget to",
  "Remember to",
  "Nice work remembering to",
  "Looks good but you might want to",
  "Looks decent but you might want to",
  "Looks great but you might want to",
  "This might look better if you",
  "I like how you",
  "This is not how you",
  "I wouldn't do this, especially the way you",
  "Sweet, looks like you",
  "I wouldn't",
  "I can't believe you",
  "You really shouldn't",
  "If only you could",
  "You might want to document how you",
  "I'll give you a dollar if you",
  "One doesn't simply",
  "If I were trying to hack your server the first thing I would do is",
  "This adds a vulerability if you",
  "You should document this if you",
  "You should catch the error when you",
  "This can fail and throw an exception, you might want to",
  "This is why you use tabs instead of spaces otherwise you will",
  "I think lodash has a built in function for you to",
  "It might be more clear to break this out into assembly to",
  "This must not be your first rodeo... I like how you",
  "Impressive! I didn't realize that you could",
  "Nice work here, didn't realize you could",
  "Wow... You",
  "We already have a function in our utils package that can",
  "I have a macro set up to automatically",
  "I know this is nitpicking, but can you",
  "Our style guide usually says to",
  "The official style guide says to always",
  "There's a package that will help you"
];

export const questions = [
  "What if you",
  "Would you mind trying to",
  "Does your team like it when you",
  "Does your manager know that you",
  "Does your product manager know that you",
  "Do your users know that you",
  "Does your mom know that you",
  "Can you double check if you",
  "Can you leave a comment about how you",
  "Does your team usually",
  "Sorry, I know this is annoying but can you",
  "Did your auto-formatter accidentally",
  "Is there a reason not to",
  "I didn't know that you",
  "Are you sure that you want to",
  "Is this how you normally",
  "Interesting, should you really",
  "Wow... Did you really",
  "How interesting, I didn't know that you could"
];

export const adjectives = [
  "serverless",
  "adversarial",
  "virtual",
  "hacky",
  "synchronous",
  "asynchronous",
  "perfectly good",
  "abtract",
  "flaky",
  "unreliable",
  "reliable",
  "modular",
  "awaitable",
  "bytes-like",
  "global",
  "mutable",
  "immutable",
  "hashable",
  "iterable",
  "meta",
  "interactive",
  "garbage-collected",
  "nested-scope",
  "recursive",
  "Pythonic",
  "special",
  "triple-quoted",
  "aliased",
  "variable",
  "zen",
  "pep8-compliant",
  "performant",
  "robust",
  "multithreaded",
  "blockchain-backed",
  "clean",
  "secure",
  "cyber",
  "AWS",
  "truthy",
  "falsy",
  "golang",
  "python",
  "javascript",
  "React",
  "PHP",
  "C#",
  "swift",
  "ruby",
  "fortran"
];

export const verbs = [
  "destructure",
  "use code generation for",
  "coalesce",
  "debounce",
  "redirect",
  "encrypt",
  "decrypt",
  "modularize",
  "genericize",
  "yield",
  "override",
  "benchmark",
  "refactor",
  "iterate over",
  "flatten",
  "printf",
  "spawn",
  "debug",
  "shard",
  "generate",
  "initialize",
  "deinitialize",
  "deconstruct",
  "cache",
  "persist",
  "sync",
  "download",
  "XOR",
  "upload",
  "synchronize",
  "merge",
  "diff",
  "ping",
  "deadlock",
  "update",
  "await",
  "enqueue",
  "pop",
  "push",
  "unit test",
  "code review",
  "resolve",
  "curry",
  "document",
  "rate limit",
  "unmarshall",
  "throttle",
  "binary search",
  "dereference",
  "data dump",
  "bubble sort",
  "merge sort",
  "vendor",
  "pipe",
  "orchestrate",
  "sanitize",
  "log",
  "reduce"
];

export const nouns = [
  "test suite",
  "inline styles",
  "lodash functions",
  "json",
  "protobufs",
  "spaces",
  "tabs",
  "adversarial networks",
  "console.log's",
  "booleans",
  "punch cards",
  "constructor",
  "tensors",
  "neural net",
  "environment variables",
  "middleware",
  "cryptocurrency",
  "blockchain implementation",
  "file handle",
  "closures",
  "callbacks",
  "es6 syntax",
  "alert prompts",
  "while loops",
  "cryptography",
  "gradient descent",
  "generics",
  "constants",
  "variables",
  "smart contracts",
  "modules",
  "switch statement fallthroughs",
  "pointers",
  "packages",
  "interfaces",
  "circular buffer",
  "list comprehensions",
  "graph",
  "React components",
  "stateless functional components",
  "networking",
  "concensus algorithms",
  "defer statements",
  "Raft concensus implementation",
  "JIRA tickets",
  "GitHub issues",
  "multi-part uploads",
  "JWT tokens",
  "authentication headers",
  "process monitors",
  "buffer",
  "dependencies",
  "imports",
  "functions",
  "for loop",
  "code",
  "algorithms",
  "file paths",
  "async functions",
  "dispatch queue",
  "queue",
  "array",
  "heap",
  "threads",
  "ssd",
  "memcache",
  "goroutines",
  "redis shards",
  "mysql database",
  "postgres database",
  "mutex",
  "readers-writer locks",
  "droplets",
  "nosql database",
  "lamda functions",
  "containers",
  "ec2 instance",
  ".gitignore file",
  "kubernetes",
  "docker swarm",
  "code review",
  "channels",
  "promises",
  "monads",
  "documentation",
  "nginx config",
  "queries",
  "hash map",
  "ordered set",
  "variable names",
  "function names",
  "vim bindings",
  "autogenerated code",
  "protocols",
  "class names",
  "unix timestamps",
  "ISO8601 timestamps",
  "date formatter",
  "epoch time offset",
  "ternaries",
  "DOM structure",
  "Sentry errors",
  "bug",
  "crash reports",
  "swap file",
  "hacks",
  "sha1 hashes",
  "sha256 hashes",
  "md5 hashes",
  "checksum",
  "vectors",
  "unsafe unretained references",
  "weak references",
  "strong references",
  "reference counting",
  "atomic reference counting",
  "compiler flags",
  "abstract base class",
  "dunder methods",
  "generator",
  "iterator",
  "generator iterator",
  "context manager",
  "coroutine",
  "decorator",
  "descriptor",
  "f-strings",
  "garbage collection",
  "method resolution order",
  "named tuples",
  "parameters"
];
