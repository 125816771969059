import React, { Component } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getComment } from "./comment";

class App extends Component {
  state = {
    comment: getComment()
  };
  constructor(props) {
    super(props);
    this.fetchComment();
  }
  fetchComment = () => {
    this.setState({ comment: getComment() });
  };
  render() {
    const { comment } = this.state;
    return (
      <div className="App-container">
        <h1 className="App-title">Code Review Comments</h1>
        <h3>from your annoying co-worker</h3>
        <div className="Browser">
          <div className="Browser-titleBar">
            <div className="Browser-closeButton Browser-button" />
            <div className="Browser-minimizeButton Browser-button" />
            <div className="Browser-maximizeButton Browser-button" />
          </div>
          <div className="Code-imgContainer">
            <img
              src="/images/code.png"
              className="Code-img"
              alt="code-snippet"
            />
          </div>
          <div className="Comment-container">
            <div className="Comment-avatarContainer">
              <FontAwesomeIcon icon={faUser} size="2x" color="darkgray" />
            </div>
            <div className="Comment-textContainer">
              <div className="Comment-username">YourAnnoyingCoworker</div>
              <div className="Comment-text">{comment}</div>
            </div>
          </div>
        </div>
        <button className="App-generateButton" onClick={this.fetchComment}>
          Generate
        </button>
        <div className="App-wantCodeReviewContainer">
          <a href="https://www.pullrequest.com" className="App-wantCodeReview">
            Want better code review?
            <div className="App-prlogo" />
          </a>
        </div>
      </div>
    );
  }
}

export default App;
