import { adjectives, nouns, phrases, questions, verbs } from "./data";

function rand(n) {
  return Math.round(Math.random() * (n - 1));
}

export function getComment() {
  let phrase = "";
  let punctuation = "";
  let verb = "";
  let adjective = "";
  let noun = "";

  if (rand(2) === 1) {
    phrase = phrases[rand(phrases.length)];
    punctuation = ".";
  } else {
    phrase = questions[rand(questions.length)];
    punctuation = "?";
  }
  verb = verbs[rand(verbs.length)];
  if (rand(3) === 1) {
    adjective = adjectives[rand(adjectives.length)] + " ";
  }
  noun = nouns[rand(nouns.length)];
  return `${phrase} ${verb} your ${adjective}${noun}${punctuation}`;
}
